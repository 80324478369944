/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

require('./events');


if ($('.auto-numeric').length) {
    $('.auto-numeric').on('change keyup', function () {
        putComma($(this));
    });

    $(document).ready(function () {
        $('.auto-numeric').each(function () {
            // putComma($(this));
        })
    })
}


function putComma($el) {
    var num = $el.val();
    num = num.replace(/[.]/g, ',')
    num = num.replace(/[^0-9,]/g, '')

    $el.val(num);
}

$('[data-toggle="popover"]').popover({
    html: true,
    container: 'body',
});

toggleDataToggleOnTable();

$(window).resize(() => {
    toggleDataToggleOnTable();
});

function toggleDataToggleOnTable() {
    const small = window.innerWidth < 768;
    const targetEl = $('.emissions-table-wrapper[data-toggle="popover"]');
    if(small) {
        targetEl.popover('dispose');
    } else {
        targetEl.popover({
            html: true,
            container: 'body',
        });
    }
}

$(document).ready(function () {
    const table = $('.data-table').DataTable({
        language: {
            url: `${APP_URL ?? ''}/lang/es-ES.json`
        },
    });
});
