
Livewire.on('element.updated', () => {
    fitSections();
});


$(document).ready(function () {
    fitSections();

    $(window).resize(function () {
        fitSections();
    });
});

function fitSections() {
    var wrapper = $('.section-wrapper');

    if (wrapper.length) {
        var winH = $(window).outerHeight();
        var navH = $('nav.navbar').length ? $('nav.navbar').outerHeight() : 0;
        var subnavH = $('.subnav').length ? $('.subnav').outerHeight() : 0;

        var available = parseInt(winH) - parseInt(navH) - parseInt(subnavH);
        var maxHeight = available - parseInt(wrapper.css('padding'));

        wrapper.outerHeight(maxHeight);
        wrapper.css('max-height', maxHeight + 'px');
    }
}

Livewire.on('confirm-delete', (message, ...params) => {
    Swal.fire({
        title: message,
        text:'Los datos se eliminarán para siempre',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Sí',
    }).then((result) => {
        if (result.value) {
            Livewire.emit('confirmed-delete', ...params);
        }
    })
});

Livewire.on('delete-completed', () => {
    toastr.clear();
    toastr.success('Se ha eliminado correctamente', 'Operación completada', {timeOut: 5000})
    setTimeout(function () {
        location.reload();
    }, 1800);
});

Livewire.on('error', (message) => {
    toastr.error(message, 'Ha ocurrido un error')
});

Livewire.on('success', (message = null, redirectUrl = null, timeout = 2800) => {
    toastr.clear();


    toastr.success(null, message ?? 'Operación completada', {
        timeOut: timeout
    });


    if (redirectUrl) {
        setTimeout(() => {
            window.location.href = redirectUrl;
        }, timeout);
    }
});

Livewire.on('goBackConfirm', (url = null) => {
    Swal.fire({
        title: '¿Quieres salir sin guardar?',
        text: 'Se perderán los datos si sales de esta página.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, salir',
        cancelButtonText: 'No'
    }).then((result) => {
        if (result.value) {
            if (url) {
                window.location = url;
            } else {
                if ('referrer' in document) {

                    // window.location = document.referrer;
                    /* OR */
                    location.replace(document.referrer);
                } else {
                    window.history.back();
                }
            }
        }
    })
});

